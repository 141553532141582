<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,PingFang SC,Noto Sans,Roboto,Microsoft Yahei,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-style: normal;
  font-display: swap;
  line-height: 1.5;
}
a {
    text-decoration: none;
}
ul {
    list-style: none;
}
img {
    margin: 0;
    padding: 0;
}
:root {
    font-size: 12px;
}

.index {
  min-height: 100vh;
  background-color: #FAFBFD;
  /* 导航栏 */
  .header {
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #FFFFFF;
    i {
      font-size: 26px;
    }
    svg {
      width: 26px;
      height: 26px;
    }
  }
  
  /* 底部 */
  .footer {
    width: calc(100% - 40px);
    font-size: 14px;
    padding: 40px 20px;
    text-align: center;
    background-color: #FAFBFD;
    .footText {
      width: fit-content;
      display: inline-block;
      color: #111924;
      padding-bottom: 10px;
    }
    .footButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: 50px;
      color: #FFFFFF;
      margin: auto;
      padding: 0 20px;
      border-radius: 10px;
      svg {
        padding-right: 10px;
      }
    }
  }
}
</style>
