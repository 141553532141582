import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
 
const routes = [
  {
    path: '/index',
    redirect: '/'
  },
  {
    path:'/',
    name: 'Index',
    component: () => 
        import('../views/Index.vue'),
    
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => 
        import('../views/Article.vue'),
  },
  {
    path: '/chatBot',
    name: 'ChatBot',
    component: () => 
        import('../views/ChatBot.vue'),
  }
]
 
const router = new VueRouter({
  mode: 'history',
  routes
})
 
export default router