const ChineseSimplified = {
	// 中文简体
	index: {
		center: "帮助中心",
		welcome: "您好，我们能如何为您效劳？",
		search: "搜索文章",
		tips: "抱歉，我们找不到任何东西。",
		help: "需要更多帮助？",
		contact: "联系我们",
	},
	article: {
		article: "文章",
		update: "最后更新",
		helpfun: "本文是否有帮助？",
		yes: "是",
		no: "否",
	},
	chatbot: {
		chat: "游戏支持",
		hello: "嗨，冒险家，很高兴帮您解决问题！",
		notThese: "以上问题都不是",
		sorry: "亲爱的冒险家，很抱歉我们没有查询到您的问题，请点击下方按钮联系人工客服。我们会尽快回复您！",
		customer: "联系人工客服",
	}
};
const ChineseTraditional = {
	// 中文繁体
	index: {
		center: "幫助中心",
		welcome: "您好，我們能如何為您效勞？",
		search: "搜索文章",
		tips: "抱歉，我們找不到任何東西。",
		help: "需要更多幫助？",
		contact: "聯繫我們",
	},
	article: {
		article: "文章",
		update: "最後更新",
		helpfun: "本文是否有幫助？",
		yes: "是",
		no: "否",
	},
	chatbot: {
		chat: "遊戲支持",
		hello: "嗨，冒險家，很高興幫您解決問題！",
		notThese: "以上問題都不是",
		sorry: "親愛的冒險家，很抱歉我們沒有查詢到您的問題，請點擊下方按鈕聯繫人工客服。我們會盡快回复您！",
		customer: "聯繫人工客服",
	}
};
const English = {
  	// 英语
	index: {
        center: "SUPPORT CENTER",
        welcome: "Hello, how can we help you?",
        search: "Search for articles",
		tips: "Sorry, but we couldn’t find anything.",
        help: "Need more help?",
        contact: "Contact us",
    },
    article: {
        article: "Article",
        update: "Last update",
        helpfun: "Was this article helpful?",
        yes: "Yes",
        no: "No",
    },
    chatbot: {
        chat: "Game Support",
        hello: "Hi Adventurer, glad to help you out!",
		notThese: "None of the above",
        sorry: "Dear adventurer, we are sorry that we did not find your question. Please click the button below to contact customer service. We will get back to you as soon as possible!",
        customer: "Contact Customer Service",
    }
};
const French = {
	// 法语
	index: {
		center: "CENTRE DE SOUTIEN",
		welcome: "Bonjour, comment pouvons-nous vous aider ?",
		search: "Rechercher des articles",
		tips: "Désolé, mais nous n'avons rien trouvé.",
		help: "Besoin de plus d'aide ?",
		contact: "Contactez-nous",
	},
	article: {
		article: "Article",
		update: "Dernière mise à jour",
		helpfun: "Cet article a-t-il été utile?",
		yes: "Oui",
		no: "Non",
	},
	chatbot: {
		chat: "Assistance pour le jeu",
		hello: "Salut aventurier, ravi de vous aider !",
		notThese: "Aucune des réponses ci-dessus",
		sorry: "Cher aventurier, nous sommes désolés de ne pas avoir trouvé votre question. Veuillez cliquer sur le bouton ci-dessous pour contacter le service client. Nous vous répondrons dans les plus brefs délais !",
		customer: "Plus de support",
	}
};
const German = {
  // 德语
  index: {
	center: "SUPPORT-CENTER",
	welcome: "Hallo, wie können wir Ihnen helfen?",
	search: "artikel durchsuchen",
	tips: "Entschuldigung, aber wir konnten nichts finden.",
	help: "Benötigen Sie weitere Hilfe?",
	contact: "Kontaktiere uns",
	},
	article: {
		article: "Artikel",
		update: "Letztes Update",
		helpfun: "War dieser Artikel hilfreich?",
		yes: "Ja",
		no: "Nein",
	},
	chatbot: {
		chat: "Spielunterstützung",
		hello: "Hallo Abenteurer, ich freue mich, Ihnen helfen zu können!",
		notThese: "Keines der oben genannten",
		sorry: "Lieber Abenteurer, es tut uns leid, dass wir Ihre Frage nicht gefunden haben. Bitte klicken Sie auf die Schaltfläche unten, um den Kundendienst zu kontaktieren. Wir werden uns so schnell wie möglich bei Ihnen melden!",
		customer: "Mehr Unterstützung",
	}
};
const Russian = {
	// 俄语
	index: {
		center: "ЦЕНТР ПОДДЕРЖКИ",
		welcome: "Здравствуйте, чем мы можем вам помочь?",
		search: "поиск статей",
		tips: "Извините, но мы ничего не смогли найти.",
		help: "Нужна дополнительная помощь?",
		contact: "Связаться с нами",
	},
	article: {
		article: "статья",
		update: "Последнее обновление",
		helpfun: "Была ли эта статья полезна?",
		yes: "Да",
		no: "Нет",
	},
	chatbot: {
		chat: "Поддержка игры",
		hello: "Привет, искатель приключений, рад помочь тебе!",
		notThese: "Ни один из вышеперечисленных",
		sorry: "Дорогой искатель приключений, нам жаль, что мы не нашли ваш вопрос. Пожалуйста, нажмите кнопку ниже, чтобы связаться со службой поддержки клиентов. Мы свяжемся с вами как можно скорее!",
		customer: "Дополнительная поддержка",
	}
};
const Spanish = {
	// 西班牙语
	index: {
		center: "CENTRO DE APOYO",
		welcome: "Hola, ¿cómo podemos ayudarte?",
		search: "Buscar artículos",
		tips: "Lo sentimos, pero no pudimos encontrar nada.",
		help: "Necesitas más ayuda?",
		contact: "Contáctenos",
	},
	article: {
		article: "Artículo",
		update: "Última actualización",
		helpfun: "¿Te resultó útil este artículo",
		yes: "Sí",
		no: "No",
	},
	chatbot: {
		chat: "Soporte de juego",
		hello: "Hola aventureros, ¡encantado de poder ayudarte!",
		notThese: "Ninguna de las anteriores",
		sorry: "Estimado aventurero, lamentamos no haber encontrado su pregunta. Haga clic en el botón a continuación para ponerse en contacto con el servicio de atención al cliente. ¡Nos comunicaremos con usted lo antes posible!",
		customer: "Más soporte",
	}
};
const PortuguesePortugal = {
	// 葡萄牙
	index: {
		center: "CENTRO DE APOIO",
		welcome: "Olá, como podemos ajudá-lo?",
		search: "Pesquisar artigos",
		tips: "Desculpe, mas não conseguimos encontrar nada.",
		help: "Precisa de mais ajuda?",
		contact: "Contate-nos",
	},
	article: {
		article: "Artigo",
		update: "Última atualização",
		helpfun: "Esse artigo foi útil?",
		yes: "Sim",
		no: "Não",
	},
	chatbot: {
		chat: "Suporte ao jogo",
		hello: "Olá aventureiros, é um prazer ajudá-los!",
		notThese: "Nenhuma das acima",
		sorry: "Caro aventureiro, lamentamos não termos encontrado sua pergunta. Clique no botão abaixo para entrar em contato com o atendimento ao cliente. Entraremos em contato com você o mais breve possível!",
		customer: "Mais suporte",
	}
};
const Japanese = {
	// 日本
	index: {
		center: "サポートセンター",
		welcome: "こんにちは。何かお手伝いできることはありますか?",
		search: "記事を検索する",
		tips: "申し訳ありませんが、何も見つかりませんでした。",
		help: "もっと助けが必要ですか？",
		contact: "お問い合わせください",
	},
	article: {
		article: "記事",
		update: "最新のアップデート",
		helpfun: "この記事は役に立ちましたか?",
		yes: "はい",
		no: "いいえ",
	},
	chatbot: {
		chat: "ゲームサポート",
		hello: "こんにちは、冒険者。問題の解決をお手伝いできることをうれしく思います。",
		notThese: "上記のどれでもない",
		sorry: "冒険者の皆様、ご質問が見つからなかったことをお詫び申し上げます。下のボタンをクリックしてカスタマー サービスにお問い合わせください。 できるだけ早くご連絡させていただきます。",
		customer: "さらなるサポート",
	}
};
const Korean = {
	// 韩国
	index: {
		center: "지원 센터",
		welcome: "안녕하세요, 무엇을 도와드릴까요?",
		search: "기사 검색",
		tips: "죄송합니다. 아무것도 찾을 수 없습니다.",
		help: "도움이 더 필요하신가요?",
		contact: "문의하기",
	},
	article: {
		article: "기사",
		update: "최근 업데이트",
		helpfun: "이 글이 도움 되었나요?",
		yes: "예",
		no: "아니요",
	},
	chatbot: {
		chat: "게임 지원",
		hello: "모험가 여러분, 안녕하세요. 도와드리게 되어 기쁘게 생각합니다!",
		notThese: "위에 해당사항 없음",
		sorry: "모험가님, 귀하의 질문을 찾지 못해 죄송합니다. 아래 버튼을 클릭하여 고객 서비스에 문의하세요. 최대한 빨리 연락드리겠습니다!",
		customer: "더 많은 지원",
	}
};
const Italian = {
	// 意大利
	index: {
		center: "CENTRO DI SUPPORTO",
		welcome: "Ciao, come possiamo aiutarti?",
		search: "Cerca articoli",
		tips: "Siamo spiacenti, ma non siamo riusciti a trovare nulla.",
		help: "Serve ancora aiuto?",
		contact: "Contattaci",
	},
	article: {
		article: "Articolo",
		update: "Ultimo aggiornamento",
		helpfun: "questo articolo è stato utile?",
		yes: "SÌ",
		no: "NO",
	},
	chatbot: {
		chat: "Supporto al gioco",
		hello: "Caro giocatore, felice di aiutarti!",
		notThese: "Nessuna delle precedenti",
		sorry: "Caro giocatore, ci dispiace di non aver trovato la tua domanda. Fare clic sul pulsante in basso per contattare il servizio clienti. Ti risponderemo il prima possibile!",
		customer: "Contattare il Servizio Clienti",
	}
};

export default{
	ChineseSimplified, // 简体中文
	ChineseTraditional, // 繁体中文
	English, // 英语
	French, // 法语
	German, // 德语
	Russian, // 俄语
	Spanish, // 西班牙语
	PortuguesePortugal, // 葡萄牙语
	Japanese, // 日语
	Korean, // 韩语
	Italian, // 意大利
}